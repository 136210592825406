import { Col, Row, Button, Form, Input, Spin, notification, Empty, Radio } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from '@reach/router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from '@reach/router'
import { Helmet } from 'react-helmet'
import client from '../../../api/client'
export default function CustomsEdit() {
  const customsId = useParams()?.id
  const orderId = useParams()?.orderId
  const navigate = useNavigate()
  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)

  const ignoredFields = ['id', 'orderId', 'shipmentId', 'actionDate', 'createdDate', 'editedBy', 'gaaliStatus']

  const [customsLookup, setCustomsLookup] = useState([])

  const [customsFields, setCustomsFields] = useState([])
  const [customsFieldsKeys, setCustomsFieldsKeys] = useState([])

  const [gaaliApiRequestId, setGaaliApiRequestId] = useState(null)

  const fetchCustomsDetail = useCallback(() => {
    if (customsFields.length === 0) {
      setLoading(true)
      const url = `/api/gaaliapi/getgaaliapirequest/${customsId}/${orderId}`
      client
        .get(url)
        .then((res) => {
          if (res.status === 200) {
            setGaaliApiRequestId(res.data.id)

            const filteredData = Object.keys(res.data)
              .filter((key) => !ignoredFields.includes(key))
              .reduce((result, key) => {
                result[key] = res.data[key]
                return result
              }, {})
            form.setFieldsValue(filteredData)
            setCustomsFields(filteredData)
            setCustomsFieldsKeys(Object.keys(filteredData))
            setLoading(false)
          }
        })
        .catch((err) => {
          notification.error({ message: 'Алдаа гарлаа' })
          setLoading(false)
        })
    }
  }, [])
  const fetchFieldLabels = () => {
    setLoading(true)
    const url = '/api/gaaliapi/gaaliapirequests/field-labels'
    client
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          setCustomsLookup(
            res.data?.reduce((acc, item) => {
              acc[item.apiField.toLowerCase()] = item.label
              return acc
            }, {}),
          )
        }
        setLoading(false)
      })
      .catch((err) => {
        notification.error({ message: 'Алдаа гарлаа' })
        setLoading(false)
      })
  }
  useEffect(() => {
    fetchFieldLabels()
  }, [])
  useEffect(() => {
    fetchCustomsDetail()
  }, [fetchCustomsDetail])

  // PUT
  const editCustomDetail = (values) => {
    setLoading(true)
    const url = `/api/gaaliapi/updategaaliapirequest/${gaaliApiRequestId}`
    const payload = values
    client
      .put(url, payload)
      .then((res) => {
        if (res.status === 200) {
          notification.success({ message: 'Амжилттай хадгалагдлаа' })
          setLoading(false)
        }
      })
      .catch((err) => {
        notification.error({ message: 'Алдаа гарлаа' })
        setLoading(false)
      })
  }

  const handleFinish = (values) => {
    editCustomDetail(values)
  }
  return (
    <>
      <Helmet title="Гаалийн мэдээлэл" defer={false} />
      {/* <div style={{ padding: '20px', maxWidth: 1366, margin: '0 auto' }} className="d-flex flex-column h-100"> */}
      <Row className="sb-table-header" gutter={[8, 8]}>
        <Col style={{ position: 'relative', width: '100%' }}>
          <Button type="link" className="sb-arrow-back" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} size="xs" />
            <span>Буцах</span>
            <Input className="sb-input" />
          </Button>
        </Col>
        <Col style={{ position: 'relative', width: '100%' }}>
          <span className="sb-text-medium">Гаалийн дэлгэрэнгүй мэдээлэл</span>
        </Col>
      </Row>
      <Spin spinning={loading}>
        <Row className="w-full" justify={'center'}>
          <Form layout="vertical" form={form} className="shipment-details" name="customs-form" onFinish={handleFinish}>
            <Row gutter={[12, 8]}>
              {customsFieldsKeys.map((key) => {
                const label = customsLookup[key.toLowerCase()]
                return (
                  <Col xs={24} sm={24} md={12} lg={8} key={key}>
                    <Form.Item
                      label={label}
                      name={key}
                      // valuePropName={['risk_type'].includes(String(key).toLowerCase()) ? 'checked' : 'value'}
                    >
                      {['risk_type'].includes(String(key).toLowerCase()) ? (
                        <Radio.Group className="sb-radio-buttons" defaultValue={customsFields[key]}>
                          <Radio value="0">Үгүй</Radio>
                          <Radio value="1">Тийм</Radio>
                        </Radio.Group>
                      ) : (
                        <Input defaultValue={customsFields[key]} className="sb-input " style={{ border: '1px solid gray' }} placeholder={label} />
                      )}
                    </Form.Item>
                  </Col>
                )
              })}
            </Row>
          </Form>
          {customsFieldsKeys?.length === 0 && <Empty description={'Мэдээлэл алга'} />}
        </Row>
      </Spin>
      <Row justify={'end'}>
        <Col flex="230px">
          <Button
            className="ant-btn btn-sb btn-sb-primary sb-shadow w-100"
            form="customs-form"
            htmlType="submit"
            loading={loading}
            disabled={customsFieldsKeys?.length === 0}
          >
            Хадгалах
          </Button>
        </Col>
      </Row>
      {/* </div> */}
    </>
  )
}
