import { Col, Row, Input, Table, Button, Tooltip, Checkbox } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faFilter } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from '@reach/router'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import client from '../../../api/client'
export default function Customs() {
  const navigate = useNavigate()
  const searchInitialValue = React.useMemo(() => {
    return typeof window !== 'undefined' ? localStorage.getItem('search-customs') || '' : ''
  }, [])
  const pageNumberInitialValue = React.useMemo(() => {
    return typeof window !== 'undefined' ? localStorage.getItem('pageNumber-customs') || 1 : 1
  }, [])
  const [loading, setLoading] = useState(false)

  const [tableData, setTableData] = useState([])
  const [dataCount, setDataCount] = useState(0)
  const [searchValue, setSearchValue] = useState(searchInitialValue)
  const [pageNumber, setPageNumber] = useState(pageNumberInitialValue)
  const [isArchived, setIsArchived] = useState(false)
  const [paginatedRequestData, setPaginatedRequestData] = useState({
    search: searchValue,
    pagination: {
      current: pageNumber,
      pageSize: 10,
    },
    sorter: {
      field: 'createdDate',
      order: 'descend',
    },
    includeContainerShipmentType: false,
    // airBill: '',
    // regNum: '',
    statusNames: [isArchived ? 'Finished' : 'New', 'Preparing'],
  })

  const [orderStatuses, setOrderStatuses] = useState([])

  const columns = [
    {
      title: 'Огноо',
      className: 'text-center',
      dataIndex: 'date',
      render: (val) => <>{moment(val).format('MM/DD/YYYY')}</>,
      sorter: true,
    },
    {
      title: 'AirBill No',
      className: 'text-center',
      dataIndex: 'airbillNumber',
      sorter: (a, b) => a.airbillNumber.localeCompare(b.airbillNumber),
    },
    {
      title: 'Илгээмжийн төрөл',
      className: 'text-center',
      render: (record) => record.transportType.name,
      // sorter: (a, b) => (SHIPMENT_TYPES[a.shipmentType] ?? '').localeCompare(SHIPMENT_TYPES[b.shipmentType] ?? ''),
    },
    {
      title: 'Статус',
      className: 'text-center',
      width: '20%',
      filterMultiple: false,
      filterIcon: () => <FontAwesomeIcon icon={faFilter} size="xs" style={{ color: '#000000' }} />,
      filters: orderStatuses.slice(0, -1).map((status) => ({
        text: status.name,
        value: status.id,
      })),
      onFilter: (value, record) => record.processStatus.id === value,
      render: (record) => record.processStatus.name,
    },
    {
      title: 'Үйлдэл',
      className: 'text-center',
      align: 'right',
      width: '10%',
      render: (record) => {
        const link = `/admin/customs/${record.id}`
        return (
          <Row style={{ width: '100%', gap: '12px', minWidth: '100px' }} align={'middle'} justify={'center'}>
            <Tooltip title="Үзэх">
              <Button className="btn-sb btn-sb-primary " onClick={() => navigate(link)}>
                <FontAwesomeIcon icon={faEye} size="sm" className="mx-2" />
              </Button>
            </Tooltip>
            {/* <Tooltip title="Засах">
              <Button className="btn-sb btn-sb-primary " onClick={() => handleExpandClick(record.id)}>
                <FontAwesomeIcon icon={faUpload} size="sm" className="mx-2" />
              </Button>
            </Tooltip> */}
          </Row>
        )
      },
    },
    // Table.EXPAND_COLUMN,
  ]
  const fetchTableData = useCallback(() => {
    setLoading(true)
    const url = '/api/gaaliapi/list'
    client
      .post(url, paginatedRequestData)
      .then((res) => {
        if (res.status === 200) {
          setTableData(res.data.data)
          setDataCount(res.data.total)
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
      })
  }, [paginatedRequestData])

  useEffect(() => {
    fetchTableData()
    client
      .get('/api/lookup/gaalistatuses')
      .then((res) => {
        if (res.status === 200) {
          setOrderStatuses(res.data)
        }
      })
      .catch((err) => console.error(err))
  }, [fetchTableData])

  return (
    <>
      <Helmet title="Гааль" defer={false} />
      <Row className="sb-table-header" gutter={[8, 8]}>
        <Col flex="auto">
          <span className="sb-text-medium">Гааль {`(${dataCount})`}</span>
        </Col>
        <Col flex="250px">
          <Input.Search
            className="w-100 sb-search sb-shadow"
            placeholder="Код, илгээгч, хүлээн авагч"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value)
              window.localStorage.setItem('search-customs', e.target.value)
            }}
            onSearch={(value) => {
              setLoading(true)
              setPageNumber(1)
              window.localStorage.setItem('pageNumber-customs', 1)
              setPaginatedRequestData({
                ...paginatedRequestData,
                search: value,
                pagination: {
                  ...paginatedRequestData.pagination,
                  current: 1,
                },
              })
            }}
          />
        </Col>
        <Col flex="230px" style={{ marginLeft: 40 }}>
          <Button className="ant-btn btn-sb btn-sb-primary sb-shadow w-100" onClick={() => navigate('/admin/customs/0')}>
            Гааль бүртгэх
          </Button>
        </Col>
      </Row>
      <Row justify="end">
        <Col span={4}>
          <Checkbox
            className="sb-checkbox"
            checked={isArchived}
            onChange={(e) => {
              setIsArchived(e.target.checked)
              setPaginatedRequestData({
                ...paginatedRequestData,
                search: '',
                statusNames: e.target.checked ? ['Finished'] : ['New', 'Preparing'],
                pagination: {
                  ...paginatedRequestData.pagination,
                  current: pageNumber,
                },
                filters: {},
              })
            }}
          >
            Дууссан гааль харах
          </Checkbox>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            // expandable={{
            //   expandedRowRender: (record) => <CustomsEditForm data={record} lookup={customLookupObject} />,
            //   expandedRowKeys: expandedKeys,
            //   expandIcon: () => null,
            // }}
            className="sb-table mt-3"
            columns={columns}
            dataSource={tableData}
            loading={loading}
            rowKey={(record) => record.id}
            locale={{ emptyText: 'Мэдээлэл байхгүй байна' }}
            onChange={(pagination, filter, sorter) => {
              setPageNumber(pagination.current)
              window.localStorage.setItem('pageNumber-customs', pagination.current)
              setLoading(true)
              if (sorter.field)
                setPaginatedRequestData({
                  ...paginatedRequestData,
                  pagination: {
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                  },
                  sorter: {
                    field: sorter.field,
                    order: sorter.order,
                  },
                })
              else
                setPaginatedRequestData({
                  ...paginatedRequestData,
                  pagination: {
                    current: pagination.current === 0 && pagination.total === 0 ? 1 : pagination.current,
                    pageSize: pagination.pageSize,
                  },
                })
            }}
            pagination={{
              total: dataCount,
              current: typeof window !== 'undefined' ? parseInt(localStorage.getItem('pageNumber-customs')) || 1 : 1,
              pageSize: paginatedRequestData.pagination.pageSize,
            }}
          />
        </Col>
      </Row>
    </>
  )
}
