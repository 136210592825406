import { Router } from '@reach/router'
import React from 'react'
import { isAdmin, isManager, isOwner } from '../../api/auth'
import PrivateAdminRoute from '../../shared/PrivateAdminRoute'
import BulkOrders from './bulkorders'
import BulkDetails from './bulkorders/details'
import Configuration from './configuration'
import Login from './login'
import Orders from './orders'
import OrderDetails from './orders/details'
import OrderEdit from './orders/edit'
import Report from './reports'
import Shipments from './shipments'
import ShipmentDetails from './shipments/details'
import Users from './users'
import SenderDetails from './users/sender/details'
import BoxDetails from './shipments/details/box/details'
import Customs from './customs'
import CustomsEdit from './customs/edit'
import CustomsDetail from './customs/detail'

const AdminPortal = () => {
  return (
    <Router basepath="/admin">
      <Login path="/login" />

      <PrivateAdminRoute path="/" default component={isManager() ? Report : Orders} />
      {isAdmin() || isOwner() ? (
        <>
          <PrivateAdminRoute path="/orders" component={Orders} />
          <PrivateAdminRoute path="/orders/:orderId" component={OrderDetails} />
          <PrivateAdminRoute path="/orders/edit/:orderId" component={OrderEdit} />

          <PrivateAdminRoute path="/bulkorders/details/:bulkId" component={BulkDetails} />
          <PrivateAdminRoute path="/bulkorders" component={BulkOrders} />

          <PrivateAdminRoute path="/users" component={Users} />
          <PrivateAdminRoute path="/users/sender/:userId" component={SenderDetails} />

          <PrivateAdminRoute path="/configuration" component={Configuration} />

          <PrivateAdminRoute path="/customs" component={Customs} />
          <PrivateAdminRoute path="/customs/:id" component={CustomsDetail} />
          <PrivateAdminRoute path="/customs/:id/:orderId" component={CustomsEdit} />
        </>
      ) : (
        []
      )}
      <PrivateAdminRoute path="/shipments" component={Shipments} />
      <PrivateAdminRoute path="/shipments/:shipmentId" component={ShipmentDetails} />
      <PrivateAdminRoute path="/shipments/:shipmentId/package/:packageId/:branchId" component={BoxDetails} />

      <PrivateAdminRoute path="/reports" component={Report} />
    </Router>
  )
}

export default AdminPortal
